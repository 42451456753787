import React, {useContext, useEffect} from "react";
import {AuthContext} from "../contexts/authcontexts";
import {useNavigate} from "react-router-dom";

export default function ClaimsCheck(props) {

  let navigate = useNavigate();
  const {userPrivilege, handleSignOut} = useContext(AuthContext);

  useEffect(() => {
    if(userPrivilege.claims.teacherjambi === true || 
       userPrivilege.claims.coordinator === true || 
       userPrivilege.claims.allacces === true){
        navigate("/")
       }else{
        alert("You are not authorized");
        handleSignOut();
        navigate("/")
       }
  }, [userPrivilege])

  return (
    <div className={`${props.widthHeight} ${props.centerVertically} ${props.centerHorizontally}`}>
      ...Checking your authorization, please wait
    </div>
  )
}
