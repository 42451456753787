import React from "react";

const cssClasses = "${props.centerVertically} ${props.centerHorizontally} ${props.maxWidthMinHeight}"

export default function UnderConstruction(props) {
  return (
    <div className={cssClasses}>
      <div className={props.box}>
        <h1>This page is under construction</h1>
      </div>
    </div>
  )
}
