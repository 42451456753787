import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import ProgressReportLinksTable from "./progressreportlinkstable";
import {StyledTab} from "../shared/styledtab";
import {progreplinksjambiartclass, progreplinksjambismartclass,
  progreplinksjambimusicclass, progreplinksjambiplayclass} from "./progreportlinks";
import {whitish} from "../styling/colors";

const tabStyles = {
  backgroundColor: whitish,
  opacity: "0.7",
  width: "95vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}

export default function ProgressReport(props) {
 
const classNames = `${props.maxWidthMinHeight} ${props.centerVertically} ${props.flexVertically}`
const [linksTable, setLinksTable] = useState(0);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
  
  return (
    <div className={classNames} style={{marginTop: "10vh"}}>
      <div id="tab">
        <Tabs
          variant="scrollable" 
          value={linksTable} 
          onChange={(e, value) => setLinksTable(value)}
          sx={tabStyles}>
            <StyledTab label="PLAY CLASS" {...a11yProps(0)}/>
            <StyledTab label="MUSIC CLASS" {...a11yProps(1)}/>
            <StyledTab label="ART CLASS" {...a11yProps(2)}/>
            <StyledTab label="SMART CLASS" {...a11yProps(3)}/>
        </Tabs>
      </div> 
      <div>
        {linksTable === 0 && 
          <ProgressReportLinksTable whichClass={progreplinksjambiplayclass}/>
        || linksTable === 1 &&
          <ProgressReportLinksTable whichClass={progreplinksjambimusicclass}/>
        || linksTable === 2 &&
          <ProgressReportLinksTable whichClass={progreplinksjambiartclass}/>
        || linksTable === 3 &&
          <ProgressReportLinksTable whichClass={progreplinksjambismartclass}/>
        }
      </div>
    </div>
  )
}