import Dashboard from "./dashboard/dashboard";
import ClaimsCheck from "./authentication/claimscheck";
import LessonPlan from "./lessonplans/lessonplan";
import Rotation from "./rotation/rotation";
import ProgressReport from "./progressreport/progressreport";
import Certificate from "./certificate/certificate";
import Attendance from "./attendance/attendance";
import UnderConstruction from "./shared/underconstruction";
import styles from "./App.module.css";
import {Routes, Route} from "react-router-dom";

function App() {

const cssProps = {
  maxWidthMinHeight: styles.maxWidthMinHeight, 
  theGrid: styles.theGrid,
  centerHorizontally: styles.centerHorizontally, 
  centerVertically: styles.centerVertically, 
  flexVertically: styles.flexVertically, 
  flexHorizontally: styles.flexHorizontally, 
  gap: styles.gap, 
  box: styles.box, 
  boxForLessonPlan: styles.boxForLessonPlan
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard {...cssProps}/>}/>
        <Route path="/claimscheck" element={<ClaimsCheck {...cssProps}/>}/>
        <Route path="/lessonplan" element={<Rotation {...cssProps}/>}/>
        <Route path="/lessonplan/:id" element={<LessonPlan {...cssProps}/>}/>
        <Route path="/progressreport" element={<ProgressReport {...cssProps}/>}/>
        <Route path="/certificate" element={<Certificate {...cssProps}/>}/>
        <Route path="/attendance" element={<Attendance {...cssProps}/>}/>
        <Route path="/underconstruction" element={<UnderConstruction {...cssProps}/>}/>
      </Routes>
    </>
  );
}

export default App;
