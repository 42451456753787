import React, {createContext, useReducer, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {firebaseAuth} from "../firebase";
import {onAuthStateChanged, signInWithEmailAndPassword, 
        setPersistence, browserSessionPersistence, signOut} from "firebase/auth";

const initialState = {
  isOnline: null,
  userPrivilege: null,
  viewNavbar: false,
}

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
let navigate = useNavigate();

const username = useRef();
const password = useRef();

function reducer(state, action){
  switch(action.type) {
    case "isOnline": return {...state, isOnline: action.payload}
    case "userPrivilege": return {...state, userPrivilege: action.payload}
    case "viewNavbar": return {...state, viewNavbar: action.payload}
    default: throw new Error("Something went wrong in reducer") 
  }
}

const [state, dispatch] = useReducer(reducer, initialState);

useEffect(() => { 
  onAuthStateChanged(firebaseAuth, (user) => {
    if (user) {
      user.getIdTokenResult().then(res => dispatch({type: "userPrivilege", payload: res})); 
      dispatch({type: "isOnline", payload: user.uid});
    }else {
      dispatch({type: "isOnline", payload: null});
      console.log("Tidak ada user yang sedang aktif");
    }});}, [])
  
useEffect(() => {
  if(state.userPrivilege === null || state.userPrivilege === undefined){
    dispatch({type: "viewNavbar", payload: false});
    }else if(
      state.userPrivilege.claims.teacherjambi === true ||
      state.userPrivilege.claims.coordinator === true || 
      state.userPrivilege.claims.allacces === true){
      dispatch({type: "viewNavbar", payload: true})
    }else{dispatch({type: "viewNavbar", payload: false})}
},[state.userPrivilege])

const handleSignIn = () =>
  setPersistence(firebaseAuth, browserSessionPersistence)
  .then(() => {
    signInWithEmailAndPassword(firebaseAuth, `${username.current.value}@kindergym.id`, password.current.value)
    .then(() => navigate("/claimscheck"))
    .catch(error => {
      const errorMessage = error.message;
      console.log(errorMessage)
      alert("Wrong password or username");
    })});

function clearUsername(){return username.current.value = ""}
function clearPassword(){return password.current.value = ""}

const handleSignOut = () => 
  signOut(firebaseAuth).then(() => {
    console.log("sign out successful");
    dispatch({type: "isOnline", payload: null});
    navigate("/");
    clearUsername();
    clearPassword();
    dispatch({type: "viewNavbar", payload: false})
  }).catch(err => {console.log(err)});

  return(
    <AuthContext.Provider 
      value={{isOnline: state.isOnline, userPrivilege: state.userPrivilege, viewNavbar: state.viewNavbar, 
              username, password, handleSignIn, handleSignOut}}>
      {props.children} 
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;