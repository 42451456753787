import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {ThemeProvider} from '@mui/material/styles';
import {StyledTableCell, StyledTableRow, theme} from "../shared/styledtable";
import {LinkButton} from "../shared/tablelink";

const tableContainerStyles = {
  width: "95vw",
  display: "flex",
  justifyContent: "center"
}

const tableStyles = {
  width: "100%",
  marginTop: "0.5rem",
  marginBottom: "2rem"
}

export default function ProgressReportLinksTable(props) {

  const whichClass = props.whichClass;

  return (
    <>
      <ThemeProvider theme={theme}>
        <TableContainer sx={tableContainerStyles}>
          <Table sx={tableStyles}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center"><h5>CLASS</h5></StyledTableCell>
                <StyledTableCell align="center"><h5>CREATE</h5></StyledTableCell>
                <StyledTableCell align="center"><h5>VIEW</h5></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {whichClass.map(progrep => (
                <StyledTableRow key={progrep.name}>
                  <StyledTableCell align="center"><h6>{progrep.name}</h6></StyledTableCell>
                  <StyledTableCell align="center">
                    <LinkButton 
                      variant="text" 
                      onClick={() => window.open(progrep.progreplink, "_blank").focus()}>
                        MAKE PROGRESS REPORT 
                    </LinkButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <LinkButton 
                      variant="text" 
                      onClick={() => window.open(progrep.replink, "_blank").focus()}>
                        VIEW REPORT
                    </LinkButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </ThemeProvider>
    </>
  )
}