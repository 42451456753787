export const certlinks = [
  {name: "PLAY CLASS",
  link: "https://form.jotform.com/222821335079455",
  view: "https://docs.google.com/spreadsheets/d/1INy7ip-QS2XmgHsszOUibigPQ3VaLbUjGzmtLeOaC6I/edit?usp=sharing"},
  {name: "MUSIC CLASS",
  link: "https://form.jotform.com/222821673120447",
  view: "https://docs.google.com/spreadsheets/d/1fQmrLlvJu9NjpppIrtQxh32aoORtyowHZhqyWNvWU_w/edit?usp=sharing"},
  {name: "ART CLASS",
  link: "https://form.jotform.com/222822100169446",
  view: "https://docs.google.com/spreadsheets/d/1tMVX_td4ITVt8XZIIGKefIhjV2oOl_2Sy08-wUXbfE8/edit?usp=sharing"},
  {name: "SMART CLASS",
  link: "https://form.jotform.com/222821683941459",
  view: "https://docs.google.com/spreadsheets/d/1ZjFnQGoCdageslQFuAXbDUC5vl5Vnjy7sD8JlCAb72A/edit?usp=sharing"}
  ]
