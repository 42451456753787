export const progreplinksjambiplayclass = [
  {name: "BABY",
  progreplink: "https://form.jotform.com/222762268710456",
  replink: "https://docs.google.com/spreadsheets/d/1K9BK8WDble8pX0jhl5ZLdqqjgnsQUNtZvzgXTANVpjM/edit?usp=sharing"},
  {name: "CRAWLER",
  progreplink: "https://form.jotform.com/222761603106447",
  replink: "https://docs.google.com/spreadsheets/d/1JXWceZPKzkgthNyw_Mfa86AcLAOyngfHNoLQy2GpuaE/edit?usp=sharing"},
  {name: "WALKER",
  progreplink: "https://form.jotform.com/222762295782465",
  replink: "https://docs.google.com/spreadsheets/d/1TiCRgxv1A0-F-zbIgLkJ-0XSDR2v-DNySrfthBIiGEU/edit?usp=sharing"},
  {name: "RUNNER",
  progreplink: "https://form.jotform.com/222770594835465",
  replink: "https://docs.google.com/spreadsheets/d/1mvWxYfSKtRouRqbEnH2xG9Uuf4JLSVSbKWsEfEW7XNU/edit?usp=sharing"},
  {name: "EXPLORER",
  progreplink: "https://form.jotform.com/222768650113454",
  replink: "https://docs.google.com/spreadsheets/d/1rCS8nxtDKA_5tvEmM9ZprLwLpLGmZ4zSSl9pXtI72M4/edit?usp=sharing"},
  {name: "ADVANCE",
  progreplink: "https://form.jotform.com/222771070139452",
  replink: "https://docs.google.com/spreadsheets/d/1eme6VW767pRy1IsEx8wbIFceRDUkfdiMk32IIZa3PJE/edit?usp=sharing"},
  ]

export const progreplinksjambimusicclass = [
  {name: "MOZART",
  progreplink: "https://form.jotform.com/222770445578464",
  replink: "https://docs.google.com/spreadsheets/d/1COCT-voLsMrjFC1icVpxqVUFBkL4G_H2vPkAU623ac4/edit?usp=sharing"},
  {name: "BEETHOVEN",
  progreplink: "https://form.jotform.com/222771176806460",
  replink: "https://docs.google.com/spreadsheets/d/1JBmmKBu0e11XbJul1JnbALK48zylq2NGylyp9ia3G40/edit?usp=sharing"},
  {name: "CHOPIN",
  progreplink: "https://form.jotform.com/222771258410452",
  replink: "https://docs.google.com/spreadsheets/d/1DB7CJWE_JTUdR-EVg53DrtLToBO7w1R6bSb8ljS1foc/edit?usp=sharing"},
  ]

export const progreplinksjambiartclass = [
  {name: "DAVINCI",
  progreplink: "https://form.jotform.com/222770725320450",
  replink: "https://docs.google.com/spreadsheets/d/1zF74FujjwnanP_Hrizer615ITjgWB8h6PKiJ8hK9wS4/edit?usp=sharing"},
  {name: "PICASSO",
  progreplink: "https://form.jotform.com/222770653164456",
  replink: "https://docs.google.com/spreadsheets/d/1drIJKjBfEEw0DzqBItEuQLu7JFodc9DM7iQDgdj0YwQ/edit?usp=sharing"},
  {name: "REMBRANDT",
  progreplink: "https://form.jotform.com/222771282294459",
  replink: "https://docs.google.com/spreadsheets/d/1lGTuNPlSLQavhPK6GDBHRJUkrxEwhXoZ2pt-2GtiUzc/edit?usp=sharing"},
  ]

export const progreplinksjambismartclass = [
  {name: "SMART CLASS I",
  progreplink: "https://form.jotform.com/222770952429462",
  replink: "https://docs.google.com/spreadsheets/d/13blFriDkAexup3PN2Ei5hGHSlpGkfjuPZbsIcu7svyE/edit?usp=sharing"},
  {name: "SMART CLASS II",
  progreplink: "https://form.jotform.com/222771444918462",
  replink: "https://docs.google.com/spreadsheets/d/1AStgpoyNWZu-QHOkcxWKMZOcFgaihM02WGgKWpVCCuc/edit?usp=sharing"},
  ]